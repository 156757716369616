import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createBlock(_component_apexchart, {
    type: "donut",
    options: _ctx.options,
    series: _ctx.series
  }, null, 8, ["options", "series"]))
}